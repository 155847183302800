<template>
   <div class="testtime">
      <div class="end" @click="endExam()">结束考试</div>
      <div class="flex-1 ">
        <div class="daojishi">
          <u-count-down ref="countDown" :time="time * 1000" format="mm:ss" @change="changeTime" ></u-count-down>
          <el-progress :percentage="percentage" color="#9166FF" define-back-color="#F6F7FB" :show-text="false" :stroke-width="10"></el-progress>
        </div>
      </div>
      
      <div class="submit" @click="submitAnswer()">{{subtitle}}</div>
      <!-- 退出考试组件 -->
      <quit-pop :show="show" @close="show = false" @quitExam="quitExam"></quit-pop>

   </div>
</template>
<script>
import QuitPop from './QuitPop.vue'
import uCountDown from './u-count-down.vue'
export default {
  components: { uCountDown, QuitPop },
    props: {
      time: {
        type:[Number],
        default: 0,
      },
      title: {
        type:String,
        default: "剩余时间",
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      },
      subtitle: {
        type:String,
        default: "提交答案",
      }
    },
   data() {
      return {
        // 进度条
        percentage: 100,
        remain_time: 100,
        //退出考试
        show: false
      }
   },
   methods: {
      submitAnswer() {
        this.$emit("confirm")
      },
      endExam() {
        this.show = true;
      },
      quitExam() {
        //获取缓存内容
        var log_detail_id = localStorage.getItem("log_detail_id") || "";
        this.$api.quitExam({
          log_detail_id: log_detail_id
        }).then(res => {
          if(res.code == 1) {
            this.$router.go(-2)
            console.log("销毁考试")
          }
        })
      },
      // 倒计时
      changeTime(e) {
        // console.log(e)
        // e: {
        //   days: 0
        //   hours: 0
        //   milliseconds: 969
        //   minutes: 2
        //   seconds: 59
        // }
        this.remain_time = e.minutes*60 + e.seconds;
        if(this.remain_time == 0) {
          this.percentage = 0;
        }else {
          this.percentage = (this.remain_time/this.time)*100;
          this.percentage = Number(this.percentage.toFixed(2));
        }
        // console.log("this.percentage",this.percentage)
      },
      initData() {
        // console.log("888")
        // console.log('curPlay',this.curPlay)
        this.reset()
      },
      // 重置
      reset() {
        this.$refs.countDown.reset();
      },
   }
}
</script>
<style  lang="scss" scoped>
.testtime {
  display: flex;
  align-items: end;
  margin: 0 20px 0;
  border-bottom: 1px solid rgba(28, 39, 76, 0.1);
  box-sizing: border-box;
  width: calc(100% - 40px);
  padding-bottom: 30px;
  .end {
    width: 129px;
    height: 45px;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #DDDFE4;
  }
  .daojishi {
    width: 508px;
    margin: 0 auto;
    // .cons {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    // }
    // .time {
    //   font-size: 30px;
    //   font-weight: bold;
    //   color: $color-title2;
    // }
  }
  .submit {
    width: 129px;
    height: 45px;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    border-radius: 2px;
    color: #fff;
    background: $color-title2;
  }
}
::v-deep .el-progress {
  margin-top: 20px;
}
</style>
