import axios from 'axios';  
import QS from 'qs';
import config from './config'
//
import store from '@/store/index';
// api 模块化
import apis from './word'

//开发环境
if (process.env.NODE_ENV == 'development') {    
	axios.defaults.baseURL = config.baseUrl;
} 
else if (process.env.NODE_ENV == 'debug') {    
	axios.defaults.baseURL = config.baseUrl;//测试环境
} 
else if (process.env.NODE_ENV == 'production') {    
	axios.defaults.baseURL = config.baseUrl;//生产环境
}
axios.defaults.timeout = 10000;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;application/json';

// 请求拦截器
axios.interceptors.request.use(    
    config => {        
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        const token = store.state.token;        
        token && (config.headers.Token = token);        
        return config;    
    },    
    error => {        
        return Promise.error(error);    
})
axios.interceptors.response.use(
    response => {   
		// 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
		// 否则的话抛出错误
		if (response.status === 200) {            
			return Promise.resolve(response);        
		} else {            
			return Promise.reject(response);        
		}    
	},
    error => {   
			console.log(error)     
			return
			if (error.response.status) {            
				switch (error.response.status) {                
					// 401: 未登录
					// 未登录则跳转登录页面，并携带当前页面的路径
					// 在登录成功后返回当前页面，这一步需要在登录页操作。                
					case 401:                    
						router.replace({                        
							path: '/login',                        
							query: { 
								redirect: router.currentRoute.fullPath 
							}
						});
						break;
	 
							// 403 token过期
							// 登录过期对用户进行提示
							// 清除本地token和清空vuex中token对象
							// 跳转登录页面                
							case 403:
									 Toast({
											message: '登录过期，请重新登录',
											duration: 1000,
											forbidClick: true
									});
									// 清除token
									localStorage.removeItem('token');
									store.commit('loginSuccess', null);
									// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
									setTimeout(() => {                        
											router.replace({                            
													path: '/login',                            
													query: { 
															redirect: router.currentRoute.fullPath 
													}                        
											});                    
									}, 1000);                    
									break; 
	 
							// 404请求不存在
							case 404:
									Toast({
											message: '网络请求不存在',
											duration: 1500,
											forbidClick: true
									});
									break;
							// 其他错误，直接抛出错误提示
							default:
									Toast({
											message: error.response.data.message,
											duration: 1500,
											forbidClick: true
									});
					}
					return Promise.reject(error.response);
    }
	}
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function get (url, params = {}, type = 'application/json') {

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
      headers: {
        'Content-Type': type
      }
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data = {}, type = 'application/json') {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: {
        'Content-Type': type
      }
    }).then(response => {
      resolve(response.data)
    }, err => {
      console.log(err)
      reject(err)
    })
  })
}

/** 
 * fileupload方法，对应fileupload请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function fileupload(url, params) {
	console.log("33",params)
	return new Promise((resolve, reject) => {
		 axios.post(url, params,{headers: {'Content-Type': 'multipart/form-data'}})
		.then(res => {
			resolve(res.data);
		})
		.catch(err =>{
			reject(err.data)
		})
	});
}

/**
 * 获取数据的接口
 */
export const api = {
  ...apis
}