import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    currAudioId: 'none',  //当前播放组件id
    userInfo: {}, //用户信息
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
  },
  mutations: {
    setToken(state, value) {
			state.token = value;
		},
    setCurrAudioId(state,payload){
      state.currAudioId = payload;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    initializeToken(state, value) {
      state.token = value;
    },
    initializeUserInfo(state, value) {
      state.userInfo = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
