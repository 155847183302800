import {
  get,
  post,
  fileupload
} from './index'

export default {
  //返回logo+图片+文字
  getSetting (params) {
    return get('/index/pc_index', params)
  },
  //获取模考-考试列表
  getExamList(params) {
    return get('/learn/exam_info', params)
  },
  //获取模考-单题试题信息
  getExamDetail(params) {
    return get('/learn/exam_detail', params)
  },
  //提交答案
  submitAnswer(params) {
    return post('/learn/exam_submit', params)
  },
  //获取模考分数
  getForecastNum(params) {
    return get('/learn/estimate', params)
  },
  //获取用户信息
  getUserInfo(params) {
    return get('/member/userinfo', params)
  },
  //获取模考记录和剩余次数
  getInfo(params) {
    return get('/index/mock_exam_paper_index', params)
  },
  //中断考试
  quitExam(params) {
    return post('/learn/drop_out', params)
  },
  //编辑用户信息
  editUserInfo(params) {
    return post('/member/user_update', params)
  },
  //编辑用户信息
  getMockDetail(params) {
    return get('/learn/exam_particulars', params)
  },
  //获取模考答题记录
  getMockRecordInfo(params) {
    return get('/learn/exam_log_info', params)
  },
  //考试模考单提详情
  getMockRecordDetail(params) {
    return get('/learn/exam_log_detail', params)
  },
  //上传音频文件
  uploadAudioFile(url,params) {
    return fileupload(url, params)
  },
  //生成二维码
  getLoginCode(params) {
    return get('/index/pc_code', params)
  },
  // 判断是否有token
  getCheckLogin(params) {
    return get('/index/pc_log', params)
  },
}