<template>
  <div class="header flex-center">
      <div class="header-content flex-between main_content">
        <div class="logo" @click="goHome()">
          <img src="../assets/image/logo.png" alt="">
        </div>
        <div class="userinfo" @click="show = !show" v-if="token">
          <img :src="userInfo.user.avatar" alt="" class="avatar" v-if="userInfo.user.avatar">
          <img src="../assets/image/avatar.png" alt="" class="avatar" v-else>
          <span class="font-14">你好，{{userInfo.user.nickname}}</span>
          <img src="../assets/image/down.png" alt="" class="down" v-if="!show">
          <img src="../assets/image/up.png" alt="" class="down" v-else>
          <!-- 悬浮用户数据 -->
          <div class="infoBox" v-if="show">
            <p @click="goInfo()">账号设置</p>
            <p style="color:#FF1818;" @click="logout()">退出登录</p>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: "Header",
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      token: 'getToken'
    }),
    ...mapGetters({  
      userInfo: 'getUserInfo'
    }),
    userInfo: {
      get() {
        return this.$store.state.userInfo;
      },
      set(val) {
        this.$store.state.userInfo = val;
      }
    },
  },
  watch: {
    '$store.state.token': {
      handler(newToken, oldToken) {
        // 在这里重新请求用户信息接口
        if (newToken !== oldToken) {
          this.getUserInfo()
        }
      },
      immediate: true // 立即执行一次
    }
  },
  methods: {
    goHome() {
      if(this.token) {
        this.$router.push('/index');
      }
    },
    goInfo() {
      this.$router.push('/info')
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.$store.commit("setToken","");
      this.$store.commit("setUserInfo","");
      this.$router.push('/');
    },
    getUserInfo() {
      this.$api.getUserInfo({}).then(res => {
        if (res.code == 1 && res.data) {
          localStorage.setItem("userInfo",JSON.stringify(res.data))
          this.$store.commit("setUserInfo", res.data);
      	}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
    background: #fff;
    height: 70px;
  
    .header-content {
      height: 100%;
      padding: 0 30px;
      width: 100%;

      .logo {
        width: 121px;
        height: 37px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .userinfo {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 99;
        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        span {
          margin-left: 10px;
          margin-right: 7px;
        }
        .down {
          width: 9px;
          height: 5px;
        }
      }

    }

}

// 悬浮数据
.infoBox {
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px 0;
  width: 133px;
  background: #fff;
  box-shadow: 0 0 12rpx 12rpx #0000000d;
  z-index: 999;
  p {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>