<template>
   <div class="index">
      <div class="audio_wrap" :audioId="audioId">
        <div class="player" :style="{padding:paddingValue}">
            <div class="df ai-center  audio">
                <img src="../assets/image/play2.png" alt="" class="playimg" v-if="isPlaying" @click="pause">
                <img src="../assets/image/play.png" alt="" class="playimg" v-else @click="play">
              <el-slider 
                        :id="audioId"
                        :disabled="false"
                        style="width: 360px;" 
                        :step="step"
                        :showTooltip="false"
                        @change="bindchange"
                        :value="curValue" 
                        :max="maxValue"
              ></el-slider>
            </div>
        </div>
    </div>
   </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
  props: {
        audioId: {
            type: String,
            default: ''
        },
        audioData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        paddingValue: {
            type: String,
            default: '12px 0 12px'
        }
    },
   data() {
      return {
        curValue: 0,
        maxValue: 0,
        isPlaying: false,
        curTimeVal: "00:00",
        audioDuration: 0,  //实际音频长度
        innerAudioContext: new Audio(),
        timer: null,
        step: 1,
        value1: 20,
      }
   },
   computed: {
      ...mapState(['currAudioId'])
    },
   created(){      
        this.maxValue = Number(this.audioData.play_time); 
        this.innerAudioContext.src=this.audioData.audio;
    },
    destroyed(){
        this.innerAudioContext.pause();
    },
   watch:{     
        curValue(){
            // this.curTimeVal=this.timeFormat(this.curValue);
        },
        currAudioId(newVal,oldVal){//监控当前播放音频组件改变
             if(oldVal===this.audioId){//如果上次正在的播放的音频组件，就是这个组件，则停止播放，并初始化； 
                this.innerAudioContext.pause();
                clearInterval(this.timer);
                this.isPlaying=false;
                this.curValue=0
            }
            if(newVal!=='none'&&newVal===this.audioId){//如果切换的播放的音频组件就是这个组件，则开始播放该音频
                console.log("333")
                this.toPlay();
            }
            

        }
    },
   methods: {
      timeFormat: function (time) {
            if (!time) return '00:00'
            let min = parseInt(time / 60);
            let sec = parseInt(time) % 60;
            if (min < 10) min = '0' + min
            if (sec < 10) sec = '0' + sec
            return (min + ':' + sec)
        },
        play(){
          console.log("currAudioId",this.currAudioId)
            if(this.currAudioId===this.audioId){
              console.log("111")
                //如果当前播放的和当前点击播放是一个音频组件，则当前点击的是暂停的录音，故继续播放
                this.toPlay()
            }else{
              console.log("222")
                //如果当前播放的和当前点击播放是不是一个音频组件，则更新当前播放音频组件id，并触发监控其改变的函数；
                this.$store.commit('setCurrAudioId', this.audioId);
            }
        },
        toPlay(){ 
            console.log("444")
            this.innerAudioContext.play();
            // console.log("444",this.innerAudioContext)
            this.isPlaying=true;
            console.log("555",this.isPlaying)
            console.log("timer",this.timer)
            clearInterval(this.timer);
           
            this.watchCurrentTime();
           
            var that = this;
            this.innerAudioContext.addEventListener( 'ended', function(e) {
                console.log('自然播放结束');
                that.innerAudioContext.pause();
                clearInterval(that.timer);
                that.isPlaying=false;
                that.curValue=0;                  
                that.$store.commit('setCurrAudioId', 'none');  
            })            
        },
        pause(){
            clearInterval(this.timer);
            this.isPlaying=false;
            this.innerAudioContext.pause();   
        },
        bindchange(e) {
            this.innerAudioContext.currentTime = e;
        },
        watchCurrentTime(){
            clearInterval(this.timer);
            console.log('定时器开始');
            this.timer=setInterval(() => {
                //监控当前音频的播放位置，更新滑块位置
                this.curValue=(this.curValue+1).toFixed(2)*1;
            }, 1000);
        },
        onDelete(){
            this.$emit('deleteAudio',this.audioData.audio);
        }
   }
}
</script>
<style  lang="scss" scoped>
*{
    font-size: 14px;
}
.df{
    display: flex;
}
.ai-center{
    align-items: center;
}
.jcc{
    justify-content: center;
}
.ml20{
    margin-left: 20px;
} 
.fs24{
    font-size: 20px;
}
.player{
  padding:18upx 0 35px;
}
.audio{
  width: 504px;
  box-sizing: border-box;
  padding: 16px;
  background: #F6F7FB;
}

.button{
  padding: 0;
  box-sizing: border-box;
}

.pause {
  width:32px;
  height:36px;
  border-style:double;
  border-width:0px 0px 0px 28px;
  color:#3d92e1;
}
.w26{
    width: 52px
}


.play {  
  height: 16px;
  border-style: solid;
  border-width: 18px 0 18px 32px;
  border-color: transparent transparent transparent #3d92e1;
}

/* 新加 */
.playimg {
  width: 40px;
  height: 35px;
  margin-right: 30px;
}

::v-deep .el-slider__runway {
  background-color: #fff!important;
}
::v-deep .el-slider__bar {
  background-color: $color-title2!important;
}
::v-deep .el-slider__button {
    width: 14px!important;
    height: 14px!important;
    border: 2px solid $color-title2!important;
}
</style>
