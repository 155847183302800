<template>
   <div class="makeCon">
      <el-dialog
        title="结束考试"
        :visible.sync="show"
        width="593px"
        @close="close"
        >
        <div class="popDialog">
            <div class="midaudio">
              <img src="../assets/image/tui.png" alt="" class="tuiimg">
              <div>中途结束考试将不返还模考次数 </div>
            </div>
            <div class="btnzu flex-x-center">
              <div @click="submit()">确定</div>
            </div>
        </div>
      </el-dialog>
   </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
   data() {
      return {
      }
   },
   methods: {
    close() {
      this.$emit("close")
    },
    //确认退出考试
    submit() {
      this.$emit("quitExam")
    },
   }
}
</script>
<style  lang="scss" scoped>
.makeCon ::v-deep .el-dialog__header {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.popDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 30px 48px;
  border-radius: 2px;
  box-sizing: border-box;
  .midaudio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tuiimg {
      width: 120px;
      height: 120x;
      margin-bottom: 20px;
    }
    
  }
  .btnzu {
    width: 100%;
    margin-top: 48px;
    div {
      width: 200px;
      height: 60px;
      line-height: 60px;
      border-radius: 2px;
      text-align: center;
      color: #fff;
      background: $color-title2;
      box-sizing: border-box;
    }
    .btn_border {
      border: 1px solid #5D6581;
      color: #5D6581!important;
      background: none!important;
    }
  }
}
</style>
