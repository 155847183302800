import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  api
} from './api/index.js' // 接口
import Config from './api/config.js';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import Header from '@/components/Header.vue' 
import CountDown from '@/components/CountDown.vue' 
import MakeAudio from '@/components/MakeAudio.vue'
import MultiAudio from '@/components/MultiAudio.vue'

Vue.component('Header', Header)
Vue.component('CountDown', CountDown)
Vue.component('MakeAudio', MakeAudio)
Vue.component('MultiAudio', MultiAudio)


import * as echarts from "echarts"
Vue.prototype.$echarts = echarts


Vue.prototype.$config = Config;
Vue.prototype.$api = api;

// 检查本地存储中是否有数据
const token = localStorage.getItem('token');
if (token) {
  store.commit('initializeToken', token);
}
const userInfo = localStorage.getItem('userInfo');
if (userInfo) {
  store.commit('initializeUserInfo', userInfo);
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
